import { observable, computed, action, autorun, toJS, IObservableArray } from "mobx";
import moment from 'moment';

import { getApi } from '../api';
import { getAppState } from './AppState';
import { BaseModel, BaseModelCollection } from './Models';


class User extends BaseModel {
  object_name: string = 'users';
  object_type: string = 'user';
  object_meta: any = {};
  @observable first_name: string = '';
  @observable last_name: string = '';
  @observable email: string = '';
  @observable is_admin: boolean = false;
  

  object_fields: any = {
    first_name: {
      "type": "text",
      "forceHideEditor": true
    },
    last_name: {
      "type": "text",
      "forceHideEditor": true
    },
    email: {
      "type": "email",
      "forceHideEditor": true
    },
    is_admin: {
      "type": "check",
      "forceHideEditor": true
    },
  }

  getTitle() {    
    return `${this.first_name} ${this.last_name}`;
  }

  getSubtitle() {
    return this.email;
  }

  getSearchKeywords() {
    let keywords = '';
    keywords = this.getTitle();
    return keywords;
  }
}


class Users extends BaseModelCollection {
  object_name: string = 'users';
  object_type: string = 'user';
  baseClass: any = User;
  object_meta: any = {};
  // @observable models: IObservableArray<Contact> = ([] as IObservableArray<Contact>);
  readonly models = observable<User>([])

  async get(id: string): Promise<User> {
    let model: any = this.baseGet(id);
    return (model as User);
  }

  
  async getCurrentUser(): Promise<User> {
    let objectApi = getApi().users;
    let data: object = await objectApi.getCurrentUser();

    let model = new User();
    model.apply(data);
    return model;
  }
  
}


let users: Users;
let getUsers = () => {
    if (!users) {
        users = new Users();
    }

    return users;
}

export {
  User,
  Users,
  getUsers,
}