import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import{
  Paper,
  Typography,
  Button,
} from '@material-ui/core';

import './App.css';
import appJson from './app.json';

import StartPage from './pages/StartPage';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import InsurancePreviewPage from './pages/InsurancePreviewPage';

import {
  useLoginChecker,
} from './models';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  pageNotFoundContainer: {
    padding: 10,
  },
  pageNotFoundTitle: {
    marginBottom: 20
  },
}))



const App: React.FC = () => {
  const classes = useStyles();
  const [user, isCheckingLogin, isLoggedIn, wasLoggedIn, checkLogin, setUser] = useLoginChecker();

  if (isCheckingLogin && !isLoggedIn && !wasLoggedIn) {
    return (
      <div className="App">
        <StartPage />
      </div>
    );
  }

  if (!isLoggedIn) {
    return (
      <div className="App">
        <LoginPage
          onLogin={setUser}
          skipAutomaticLoginCheck={true}
        />
      </div>
    );
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={`${appJson.homepage}private/insurance-plan-preview/:planId/`}>
            <InsurancePreviewPage />
          </Route>
          <Route exact path={`${appJson.homepage}`}>
            <DashboardPage />
          </Route>
          <Route>
            <Paper className={classes.pageNotFoundContainer}>
              <Typography variant="h5" className={classes.pageNotFoundTitle}>404 Page Not Found</Typography>
              <Button variant="contained" color="primary" to={`${appJson.homepage}`} component={RouterLink}>Return to Home Page</Button>
            </Paper>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
