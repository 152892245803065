import React, {
  useEffect,
  useState
} from 'react';
import moment from 'moment';

import {
  CircularProgress,
  TextField,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Card,
  CardActions,
  Avatar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LockIcon from '@material-ui/icons/Lock';

import packageJson from '../../package.json';
import { User, getUsers } from '../models';
import { apiEndpoint, loginEndpoint } from '../api';
import logo from '../assets/img/logo.jpg';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 20,
    },
    logo: {
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    actions: {

    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
  })
);


interface LoginFormProps  {
  onLogin: (user: User) => void;
  skipAutomaticLoginCheck?: boolean;
  loginMessage?: string;
  plain?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const fetchCsrfToken = async () => {
    let url = `${apiEndpoint}csrf/`
    let response = await fetch(url, {
      credentials: 'include',
    });

    let raw = await response.json();
    console.log(raw);

    (window as any).csrfToken = raw.csrfmiddlewaretoken;
    return raw.csrfmiddlewaretoken;
  }

  const performLogin = async () => {
    setLoading(true);
    let url = loginEndpoint;
    let csrfmiddlewaretoken = '';
    try {
      csrfmiddlewaretoken = await fetchCsrfToken();
    } catch (error) {
      setError('Error contacting server.');
    }

    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('csrfmiddlewaretoken', csrfmiddlewaretoken);

    let response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: formData,
    });

    let loggedIn = false;
    try {
      let raw = await response.json();
      if (raw.loggedin) {
        loggedIn = true;
      }
    } catch (error) {
      setError('Error logging in. Please recheck your username and password.');
    }

    if (loggedIn) {
      let users = getUsers();
      let isLoggedIn = false;
      let user: any;
      try {
        user = await users.getCurrentUser();
        isLoggedIn = true;
      }
      catch (e) {
        console.error(e)
      }

      if (isLoggedIn && user) {
        props.onLogin(user);
      }

    }
    setLoading(false);
  }

  const onSubmit = (event: any) => {
    event.preventDefault();

    if (!username || !password) {
      setError('Username and Password cannot be empty!')
      return;
    }

    performLogin();
  }

  return (
    <Card>
      <img className={classes.logo} src={logo} alt="LexNational"/>
      <form
        onSubmit={onSubmit}
        className={classes.root}
        noValidate
        autoComplete="off"
      >
        <div className={classes.avatar}>
            {!loading && 
            <Avatar className={classes.icon}>
                <LockIcon />
            </Avatar>}
            {loading && <CircularProgress size={40} />}
        </div>
        <div className={classes.hint}>
            Welcome to LexNational. Please login to continue.
        </div>
        <TextField
          id="username"
          label="Username"
          type="text"
          fullWidth
          margin="normal"
          required
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          disabled={loading}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          disabled={loading}
        />
        <CardActions className={classes.actions}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? 'Logging you in...' : 'Login'}
          </Button>
        </CardActions>
        {error && <Alert severity="error">{error}</Alert>}
      </form>
    </Card>
  )
}

export default LoginForm;