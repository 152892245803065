import React, {
  useEffect,
  useState
} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Grow,
  Paper,
  Grid
} from '@material-ui/core';


import LoginForm from '../forms/LoginForm';
import { User } from '../models';
  
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    loginContainer: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      marginTop: '60px',
    },
  })
);


interface LoginProps {
  onLogin: (user: User) => void;
  skipAutomaticLoginCheck?: boolean;
  loginMessage?: string;
  plain?: boolean;
}


const LoginPage: React.FC<LoginProps> = (props) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true)
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={4} >
          <Grow in={show}>
            <div className={classes.loginContainer}>
              <LoginForm
                onLogin={props.onLogin}
                skipAutomaticLoginCheck={props.skipAutomaticLoginCheck}
                loginMessage={props.loginMessage}
                plain={props.plain}
              />
            </div>
          </Grow>
        </Grid>
      </Grid>
    </div>
  );
}

export default LoginPage;
