
import { BaseApi, ApiError } from './BaseApi';

class Users extends BaseApi {
  objectName = 'users'

  async getCurrentUser() {
    let raw;
    let url =`${this.apiEndpoint}current-users/`;

    try {
      let response = await fetch(url, {
        credentials: 'include'
      });

      raw = await response.json();
      // console.log(raw);
    }
    catch (e) {
      throw new Error(`Error fetching profile data!`);
      console.error(e);
    }

    if (raw.length < 1) throw new Error(`Error: profile data not found!`);

    let userProfile = raw[0];

    if (userProfile.csrf_token) {
      (window as any).csrfToken = userProfile.csrf_token;
    }

    if ((window as any).Raven) {
      console.log('setting up raven context');
      let Raven = (window as any).Raven;
      Raven.setUserContext({
        id: userProfile.id,
        username: userProfile.username,
        name: `${userProfile.first_name} ${userProfile.last_name}`,
        email: userProfile.email,
      })
    }

    return userProfile;
  }
  
}


export {
  Users,
};
