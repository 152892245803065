import React, {
  useEffect,
  useState
} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Grow,
  Paper,
  Grid,
  CardActions,
  Button
} from '@material-ui/core';

import logo from '../assets/img/logo.jpg';

import {
  getAppState
} from '../models';

const appState = getAppState()

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginTop: '60px',
    },
    logo: {
      maxWidth: '200px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '40px',
    },
  })
);


const DashboardPage: React.FC = () => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true)
  }, []);

  const user = appState.userProfile;

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={4} >
          <Grow in={show}>
            <Paper className={classes.paper}>
              <img className={classes.logo} src={logo} alt="LexNational"/>

              <CardActions>
                {user && user.is_admin &&
                  <Button
                    variant="contained"
                    color="primary"
                    href="/admin/"
                  >Admin Area</Button>
                }
                <Button
                  variant="contained"
                  href="/accounts/logout/"
                >Logout</Button>
              </CardActions>
            </Paper>
          </Grow>
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardPage;
