import React, {
  useEffect,
  useState
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Grow,
  Paper,
  Grid,
  CardActions,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core';

import PlanPreviewForm from '../forms/PlanPreviewForm';
import PlanPreviewRates from '../components/PlanPreviewRates';
import logo from '../assets/img/logo.jpg';

import {
  getAppState
} from '../models';
import { BaseApi } from '../api';

const appState = getAppState()

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 40,
    },
    paper: {
      padding: 40,
    },
    previewContainer: {
      marginTop: 40,
      padding: 40,
    }
  })
);


interface Customer {
  desired_face_amount: number;
  gender: string;
  smoke: boolean;
}

interface Plan {
  id: string;
  name: string;
  compute_code: string;
}

const InsurancePreviewPage: React.FC = () => {
  const classes = useStyles();

  const [customer, setCustomer] = useState({
    desired_face_amount: 12,
    gender: 'female',
    smoke: false,
  })
  const [minFaceAmount, setMinFaceAmount] = useState(0);
  const [maxFaceAmount, setMaxFaceAmount] = useState(51);
  const [plan, setPlan] = useState(undefined as Plan|undefined)
  const [isLoading, setIsLoading] = useState(false);

  const { path, url } = useRouteMatch();
  const { planId } = useParams();

  const user = appState.userProfile;

  const loadPlan = async (planId: string) => {
    if (isLoading) return;

    const api = new BaseApi();
    api.objectName = 'insurance-plans';
    setIsLoading(true);
    const data = await api.get(planId);
    setPlan(data as Plan);
    setIsLoading(false);
  };

  useEffect(() => {
    if (planId) loadPlan(planId);
  }, [planId])


  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} >
          <Paper className={classes.paper}>
            <Typography variant="h2">Preview Insurance Plan</Typography>

            <Typography variant="body2">{isLoading ? "Loading..." : plan?.name}</Typography>

            <PlanPreviewForm
              customer={customer}
              onChange={(customer: Customer, minFaceAmount: number, maxFaceAmount: number) => {
                setCustomer(customer);
                setMinFaceAmount(minFaceAmount);
                setMaxFaceAmount(maxFaceAmount);
              }}
              minFaceAmount={minFaceAmount}
              maxFaceAmount={maxFaceAmount}
            />
          </Paper>

          <Paper className={classes.previewContainer}>
            {!plan &&
              <CircularProgress />
            }
            {!!plan &&
              <PlanPreviewRates
                customer={customer}
                plan={plan}
                minFaceAmount={minFaceAmount}
                maxFaceAmount={maxFaceAmount}
              />
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default InsurancePreviewPage;
