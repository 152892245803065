import React, {
  useState,
  useEffect
} from 'react';

import * as ts from "typescript";

import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { Form, Field } from "react-final-form";

import {
  BaseApi,
} from '../api/BaseApi';


const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 600
  },
  table: {

  }
}));

interface Row {
  age: number;
  rate: number | string | null;
}

interface Col {
  desiredFaceAmount: number;
  rows: Row[];
}

interface Customer {
  desired_face_amount: number;
  gender: string;
  smoke: boolean;
  age?: number;
}

interface Plan {
  id: string;
  name: string;
  compute_code: string;
}

let computeCode: any = undefined;

const computeRate = (plan: Plan, sourceCustomer: Customer, age: number): number | string | null => {
  const customer = Object.assign(sourceCustomer, {});
  customer.age = age;
  
  try {
    if (computeCode && computeCode.compute) {
      return computeCode.compute(customer);
    }
  } catch (error) {
    console.log('error', error);
  }
  return null;
}


export default function PlanPreviewRates(props: {
  customer: Customer;
  plan: Plan;
  minFaceAmount: number;
  maxFaceAmount: number;
}) {
  const classes = useStyles();

  const [cols, setCols] = useState<Col[]>([]);
  const [matrix, setMatrix] = useState<(string|number|null)[][]>([]);

  useEffect(() => {
    if (!props.plan?.compute_code) return;
    let result = ts.transpile(props.plan.compute_code);
    computeCode = eval(result);

  }, [props.plan?.compute_code])

  useEffect(() => {
    let cols: Col[] = [];
    let matrix: (string|number|null)[][] = [];

    for (let i=props.minFaceAmount; i <= props.maxFaceAmount; i++) {
      let rows: Row[] = [];
      for (let j=0; j<= 90; j++) {
        let customer = {
          smoke: props.customer.smoke,
          gender: props.customer.gender,
          age: j,
          desired_face_amount: i
        }
        let rate = computeRate(props.plan, customer, j);
        rows.push({
          age: j,
          rate: rate,
        });
  
        if (matrix.length < (j+1)) matrix.push([]);
  
        matrix[j][i] = rate;
      }
      cols.push({
        desiredFaceAmount: i,
        rows: rows,
      })
    }

    setCols(cols);
    setMatrix(matrix);
  
  }, [props.customer.gender, props.customer.smoke, props.minFaceAmount, props.maxFaceAmount]);

  return (<TableContainer className={classes.root}>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Age</TableCell>
            {cols.map((col, i) => (<TableCell key={`header-${i}`}>${col.desiredFaceAmount}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>

          {matrix.map((c, i) => (<TableRow key={`row-${i}`}>
              <TableCell component="th" scope="row">
                {i}
              </TableCell>
              {c.map((v, j) => (
                <TableCell key={`col-${i}-${j}`}>{v ? ((v as any).toFixed ? (v as any).toFixed(2) : v) : ''}</TableCell>
              ))}
            </TableRow>))}
          
        </TableBody>
      </Table>
    </TableContainer>)
}