import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
  Button,
  Collapse,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { Form, Field } from "react-final-form";

import {
  BaseApi,
} from '../api/BaseApi';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  mainGrid: {
    marginTop: 20,
    paddingRight: 10,
  },
  alignRight: {
    textAlign: 'right',
  },
  form: {
    marginRight: 30,
    marginBottom: 20,
  }
}));


interface Customer {
  desired_face_amount: number;
  gender: string;
  smoke: boolean;
}

interface PreviewValue {
  min_desired_face_amount: number;
  max_desired_face_amount: number;
  gender: string;
  smoke: boolean;
}

function PlanPreviewForm(props: {
  customer: Customer;
  minFaceAmount: number;
  maxFaceAmount: number;
  onChange: (customer: Customer, minFaceAmount: number, maxFaceAmount: number) => void;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (values: PreviewValue) => {
    console.log('values', values);
    setIsSaving(true);
    setTimeout(() => {
      props.onChange({
        gender: values.gender,
        smoke: values.smoke,
        desired_face_amount: (values.min_desired_face_amount + values.max_desired_face_amount) / 2,
      }, values.min_desired_face_amount, values.max_desired_face_amount);
    }, 0);
    setTimeout(() => {
      setIsSaving(false);
    }, 1000);
  };

  const onValidate = (values: PreviewValue) => {
    const errors: any = {}

    if (!values.gender) errors.gender = 'required';
    if (!values.max_desired_face_amount) errors.desired_face_amount = 'required';

    return errors;
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={onValidate}
        initialValues={{
          gender: props.customer.gender,
          smoke: props.customer.smoke,
          min_desired_face_amount: props.minFaceAmount,
          max_desired_face_amount: props.maxFaceAmount,
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            onSubmit={event => {
              handleSubmit(event)?.then(() => {
                form.reset();
              })
            }}
            className={classes.form}
          >
            <Grid container spacing={3} className={classes.mainGrid} component={Paper}>

              <Field name="gender">
                {props => (
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel id="status-label">Gender</InputLabel>
                      <Select
                        labelId="status-label"
                        id={props.input.name}
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        label={'Gender'}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      >
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="joint">Joint</MenuItem>
                        <MenuItem value="unisex">Unisex</MenuItem>
                      </Select>
                      {((props.meta.error || props.meta.submitError) && props.meta.touched ? true: false) && <FormHelperText>{(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}</FormHelperText>}
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="min_desired_face_amount">
                {props => (
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={"Minimum desired face amount"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        type="number"
                        inputProps={{min: '0'}}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="max_desired_face_amount">
                {props => (
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={"Maximum desired face amount"}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        type="number"
                        inputProps={{min: '1'}}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="smoke">
                {props => (
                  <Grid item xs={12} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.input.value}
                          onChange={props.input.onChange}
                          name={props.input.name}
                          color="primary"
                        />
                      }
                      label="Smoke"
                    />
                  </Grid>
                )}
              </Field>

              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  type="submit"
                  disabled={isSaving}
                >Compute</Button>
                {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
}

export default PlanPreviewForm;