import { observable, computed, action, autorun } from "mobx";
import { regenerateApi } from '../api';

class AppSettings {
  @observable showDeveloperSettings: boolean;
  @observable darkMode: boolean;
  @observable debugMode: boolean;
  @observable mockData: boolean;

  constructor(source: any) {
    this.showDeveloperSettings = source.showDeveloperSettings === 'true' ? true : false;
    this.darkMode = source.darkMode === 'true' ? true : false;
    this.debugMode = source.debugMode === 'true' ? true : false;
    this.mockData = source.mockData === 'true' ? true : false;
  }
}

let settings: AppSettings;

let getAppSettings = () => {
  if (!settings) {
    if (window.localStorage) {
      settings = new AppSettings(window.localStorage);
    }
    else {
      settings = new AppSettings({});
    }
    autorun(() => {
      window.localStorage.showDeveloperSettings = settings.showDeveloperSettings;
      window.localStorage.darkMode = settings.darkMode;
      window.localStorage.debugMode = settings.debugMode;
      window.localStorage.mockData = settings.mockData;
      regenerateApi(settings.mockData);
    })
  }

  return settings;
}

export {
  AppSettings,
  getAppSettings
}