import { BaseApi, apiEndpoint, loginEndpoint, logoutUrl } from './BaseApi';
import { Users } from './Users';


class Api {
  users: Users;

  constructor() {
    this.users = new Users();
  }
}


let apiInstance: Api;

function getApi(): Api {
  if (!apiInstance) {
    apiInstance = new Api();
  }
  return apiInstance;
}


function regenerateApi(mock=false) {
  apiInstance = new Api();
}

export {
  getApi,
  regenerateApi,
  BaseApi,
  apiEndpoint,
  loginEndpoint,
  logoutUrl,
  Users,
}
