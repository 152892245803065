import React, {
  useEffect,
  useState
} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Grow,
  CircularProgress,
  Grid
} from '@material-ui/core';

import Paper from '@material-ui/core/Paper';

import logo from '../assets/img/logo.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginTop: '60px',
    },
    logo: {
      maxWidth: '200px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '40px',
    },
    loading: {
      // marginLeft: 'auto',
      // marginRight: 'auto',
    }
  })
);


const StartPage: React.FC = () => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true)
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={4} >
          <Grow in={show}>
            <Paper className={classes.paper}>
              <img className={classes.logo} src={logo} alt="LexNational"/>
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            </Paper>
          </Grow>
        </Grid>
      </Grid>
    </div>
  );
}

export default StartPage;
